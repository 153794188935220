<template lang="html">
    <div style="height:100%;" class="flex flex-ai-c flex-jc-c">
        <img src="../assets/welcome.jpg" fit="cover" alt="" style="width:100%;">
    </div>
</template>

<script>
export default {
}
</script>

<style lang="css" scoped>
</style>
